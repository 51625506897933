import { useEffect, useState, useRef, FormEvent } from 'react';
import classNames from 'classnames';
import Link from 'next/link';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import Image from 'next/image';
import Text from '@/components/typography/text';
import {
  images,
  isFastShipper,
  isHighQualitySeller,
  availableCopiesText,
  titlePhotoPath,
  mapQueryBy,
  mapSortBy,
  mapPresetFilter,
} from 'sdk';
import { Title, TypesenseIndex, TypesenseSearchProps } from 'types';

import useRouteIsInTransition from '../lib/hooks/use-route-transition';
import useAnalytics from '../lib/hooks/use-analytics';
import { Links } from '../lib/helpers/link-helper';
import { analyticsBookData } from 'sdk';
import { Typesense } from 'sdk/src/clients/typesense/react-query';
import useDebounce from '~/lib/hooks/use-debounce';

import Animated from './animated';
import Pill from './pill';
import UserProfileImage from './user-profile-image';
import UserReviewStars from './user-review-stars';
import LoadableImage from './loadable-image';
import Button from './button';

import QualitySVG from '~/assets/svg/icons/quality';
import ProfileSVG from '~/assets/svg/icons/profile';
import FastShipperSVG from '~/assets/svg/icons/fast-shipper';

import styles from '../styles/components/search-input.module.scss';
import { useGlobalState } from '~/state';
import SearchIcon from '~/assets/svg/icons/search';
import MultipleBooksIcon from '~/assets/svg/icons/multiple-books';
import COLORS from '~/lib/helpers/color-helper';
import {
  MultiSearchRequestSchema,
  MultiSearchRequestsSchema,
} from 'typesense/lib/Typesense/MultiSearch';
import Callout from '~/components/callout';

const MAX_VISIBLE_RESULTS = {
  query: 3,
  product: 5,
  titles: 5,
  myListings: 5,
  authors: 2,
  user: 2,
  hashtags: 10,
};

interface Props {
  searchInputIsFocused: boolean;
  setSearchInputIsFocused: React.Dispatch<React.SetStateAction<boolean>>;
  imagePath: string;
}

export default function SearchInput({
  searchInputIsFocused,
  setSearchInputIsFocused,
}: Props) {
  const { analyticEvents } = useAnalytics();
  const { state } = useGlobalState();
  const [searchQuery, setSearchQuery] = useState('');
  const [showSuggestionsDropdown, setShowSuggestionsDropdown] = useState(false);
  const [activeResult, setActiveResult] = useState(null);
  const [searchRequestResults, setSearchRequestResults] =
    useState<typeof searchRequest>();
  const suggestionsContainerRef = useRef<HTMLUListElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const routeIsTransitioning = useRouteIsInTransition();
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const debouncedSearchQuery = useDebounce(searchQuery, 250);

  const searches: Omit<
    MultiSearchRequestsSchema['searches'][number],
    'preset'
  >[] = [
    {
      collection: 'titles',
      query_by: mapQueryBy('titles'),
      sort_by: mapSortBy('titles'),
      filter_by: 'copies:>0',
    },
    {
      collection: 'books',
      query_by: mapQueryBy('books'),
      sort_by: mapSortBy('books'),
    },
    {
      collection: 'hashtags',
      query_by: mapQueryBy('hashtags'),
      sort_by: mapSortBy('hashtags'),
      filter_by: mapPresetFilter('hashtags'),
    },
    {
      collection: 'authors',
      query_by: mapQueryBy('authors'),
      sort_by: mapSortBy('authors'),
      filter_by: 'publish:true',
    },
    {
      collection: 'users',
      query_by: mapQueryBy('users'),
      sort_by: mapSortBy('users'),
      filter_by: mapPresetFilter('users'),
    },
    {
      collection: 'books',
      query_by: mapQueryBy('books'),
      sort_by: '_text_match:desc',
      filter_by: `seller_id:${state?.user?.data?.uid}`,
    },
  ];

  const searchRequest = Typesense<any>().multiSearch({
    props: {
      commonParams: {
        q: debouncedSearchQuery,
      },
      searchRequests: {
        searches: searches as MultiSearchRequestSchema[],
      },
    },
    reactQueryOptions: {
      enabled: searchInputIsFocused,
    },
  });

  useEffect(() => {
    if (
      searchRequest.isFetched &&
      searchRequest.dataUpdatedAt !== searchRequestResults?.dataUpdatedAt
    ) {
      setSearchRequestResults(searchRequest);
    }
  }, [searchRequest, searchRequestResults]);

  function resultsForIndex(index: TypesenseIndex) {
    const searchesIndex = searches.findIndex(
      (search) => search.collection === index
    );
    return searchRequestResults?.data?.results[searchesIndex];
  }

  const HAS_SEARCH_QUERY = Boolean(searchQuery);
  const HAS_SEARCH_PRODUCT_SUGGESTIONS = resultsForIndex('books')?.found;
  const HAS_TITLE_SUGGESTIONS = resultsForIndex('titles')?.found;
  const HAS_HASHTAG_SUGGESTIONS = resultsForIndex('hashtags')?.found;
  const HAS_AUTHOR_SUGGESTIONS = resultsForIndex('authors')?.found;
  const HAS_USER_SUGGESTIONS = resultsForIndex('users')?.found;
  const SHOULD_SHOW_SEARCH_PRODUCT_RESULTS =
    HAS_SEARCH_PRODUCT_SUGGESTIONS && !HAS_TITLE_SUGGESTIONS;
  const ON_SEARCH_PAGE = pathname === '/search';
  const ON_SELLERS_SEARCH_PAGE = pathname === '/seller-search';

  const QUERY_LINK = (
    params: Partial<TypesenseSearchProps['params']>,
    path?: '/seller-search' | '/search',
    index?: TypesenseIndex
  ) =>
    Links.searchWithQueryAppRouter({
      pathname,
      searchParams,
      path: path ? path : '/search',
      options: {
        index: index ?? 'books',
        params: {
          q: params.q ?? '*',
          filter_by: params.filter_by ?? '',
          query_by: mapQueryBy(index ?? 'books'),
          sort_by: mapSortBy(index ?? 'books'),
        },
        options: {},
      },
    });

  const SEARCH_LINK = QUERY_LINK({ q: searchQuery });
  const PEOPLE_LINK = QUERY_LINK({ q: searchQuery }, '/seller-search', 'users');

  // Sync search event with UX of debounced search request.
  useEffect(() => {
    if (!Boolean(debouncedSearchQuery.length) || searchRequest.isLoading)
      return;

    async function searchEvent() {
      await analyticEvents.searched({
        query: searchQuery,
        titles: resultsForIndex('titles')?.found || 0,
        books: resultsForIndex('books')?.found || 0,
        hashtags: resultsForIndex('hashtags')?.found || 0,
        users: resultsForIndex('users')?.found || 0,
      });
    }

    searchEvent();
  }, [debouncedSearchQuery, searchRequest.isLoading]);

  useEffect(() => {
    async function productViewEvent() {
      await analyticEvents.productListView({
        eventType: 'view',
        products: resultsForIndex('books')?.hits?.map(({ document: b }) =>
          analyticsBookData(b)
        ) as any,
        source: 'search-suggestions',
        index: 'books',
        filters: [],
      });
    }

    if (
      searchRequest.isFetched &&
      searchRequest.dataUpdatedAt !== searchRequestResults?.dataUpdatedAt
    ) {
      productViewEvent();
    }
  }, [searchRequest, searchRequestResults, analyticEvents]);

  // When the route changes (eg clicking on a Book result), hide the dropdown
  useEffect(() => {
    if (routeIsTransitioning) {
      setShowSuggestionsDropdown(false);
    }
  }, [routeIsTransitioning]);

  // If a user clicks outside of the suggestions dropdown, or the search input,
  // close the suggestions dropdown
  useEffect(() => {
    function handleClickOutsideSearch(event) {
      if (
        searchInputRef &&
        !searchInputRef?.current?.contains(event.target) &&
        suggestionsContainerRef &&
        !suggestionsContainerRef?.current?.contains(event.target)
      ) {
        setShowSuggestionsDropdown(false);
      }
    }

    if (showSuggestionsDropdown) {
      document.addEventListener('mousedown', handleClickOutsideSearch);
    }

    return () =>
      document.removeEventListener('mousedown', handleClickOutsideSearch);
  }, [showSuggestionsDropdown]);

  function trackProductClick(product, index, idx) {
    analyticEvents.productClick({
      index,
      eventType: 'click',
      filters: [],
      source: 'search-suggestions',
      position: idx,
      product: analyticsBookData(product),
    });
  }

  function goToSearchPageWithQuery() {
    const search = ON_SELLERS_SEARCH_PAGE ? PEOPLE_LINK : SEARCH_LINK;

    analyticEvents?.generalTrack('Search Action', {
      type: 'submit-search',
      source: 'search-suggestions',
    });

    return router.push(search.url);
  }

  function handleInputChange(query) {
    setSearchQuery(query);
  }

  function handleSearchInputSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    goToSearchPageWithQuery();
  }

  function handleOnInputFocus() {
    setSearchInputIsFocused(true);
    setShowSuggestionsDropdown(true);
  }

  function handleHoverResult(id) {
    return setActiveResult(id);
  }

  function handleClearSearch() {
    setSearchQuery('');
    setShowSuggestionsDropdown(false);
  }

  function renderHashtagSuggestions() {
    if (HAS_HASHTAG_SUGGESTIONS && !ON_SELLERS_SEARCH_PAGE) {
      return (
        <div className={styles['suggestion-hashtag-container']}>
          <h2 className={styles['suggestion-heading']}>
            {HAS_SEARCH_QUERY ? 'Related Hashtags' : 'Popular Hashtags'}
          </h2>
          <div className={styles['suggestion-hashtags']}>
            {resultsForIndex('hashtags')
              .hits.slice(0, MAX_VISIBLE_RESULTS.hashtags)
              .map(({ document: suggestion }) => {
                return (
                  <Animated
                    key={suggestion.id}
                    animation="fadeIn"
                    duration={0.5}
                  >
                    <Pill
                      text={suggestion.id}
                      style="secondary"
                      small
                      onClick={() => {
                        analyticEvents?.generalTrack('Search Action', {
                          type: 'hashtag-click',
                          source: 'search-suggestions',
                        });
                      }}
                      link={{
                        href: QUERY_LINK({
                          filter_by: `hashtags:${suggestion.id}`,
                        }).url,
                      }}
                    />
                  </Animated>
                );
              })}
          </div>
        </div>
      );
    }
  }

  function renderMultipleBookSearch() {
    return (
      <Animated animation="fadeIn" duration={1}>
        <Callout
          title="Multi-Book Search"
          message="Find sellers offering all the books you need"
          icon={<MultipleBooksIcon size={48} />}
          iconStyleOverrides={styles['callout-multiple-books-icon']}
          button={{
            link: {
              href: '/bundle-search',
            },
          }}
          linkAction={() => {
            analyticEvents?.generalTrack('Search Action', {
              type: 'multiple-book-search-click',
              source: 'search-suggestions',
            });
          }}
          small
          style="secondary"
        />
      </Animated>
    );
  }

  function renderProductSuggestions() {
    if (
      HAS_SEARCH_PRODUCT_SUGGESTIONS &&
      SHOULD_SHOW_SEARCH_PRODUCT_RESULTS &&
      !ON_SELLERS_SEARCH_PAGE
    ) {
      return (
        <div className={styles['suggestion-book-container']}>
          <h2 className={styles['suggestion-heading']}>Books</h2>
          {resultsForIndex('books')
            .hits?.slice(0, MAX_VISIBLE_RESULTS.product)
            .map(({ document: suggestion }, index) => {
              return (
                <Animated key={suggestion.id} animation="fadeIn" duration={0.5}>
                  <div
                    className={classNames(styles['suggestion-book'], {
                      [styles['suggestion-book-active']]:
                        activeResult === suggestion.id,
                    })}
                  >
                    <Link
                      href={`/books/${suggestion.id}`}
                      passHref
                      shallow
                      onClick={() => {
                        trackProductClick(suggestion, 'books', index);
                        analyticEvents?.generalTrack('Search Action', {
                          type: 'book-click',
                          source: 'search-suggestions',
                        });
                      }}
                      onMouseEnter={() => handleHoverResult(suggestion.id)}
                      onMouseLeave={() => handleHoverResult('')}
                      onFocus={() => {
                        handleHoverResult(suggestion.id);
                      }}
                      className={classNames(styles['suggestion-book-content'])}
                    >
                      <div className={styles['suggestion-book-image']}>
                        {suggestion.photo_path && (
                          <LoadableImage
                            width={150}
                            height={150}
                            src={images(suggestion.photo_path).book.tiny}
                            alt={suggestion.title}
                          />
                        )}
                      </div>
                      <div className={styles['suggestion-book-info']}>
                        <h3>{suggestion.title}</h3>
                        <h4>{suggestion.author}</h4>
                      </div>
                    </Link>
                  </div>
                </Animated>
              );
            })}
        </div>
      );
    }
  }

  function renderTitleSuggestions() {
    if (ON_SELLERS_SEARCH_PAGE) return null;
    function renderTitlePrice(title: Title) {
      if (!title.copies) return;

      return <h5>{availableCopiesText(title)}</h5>;
    }

    if (HAS_TITLE_SUGGESTIONS) {
      return (
        <div className={styles['suggestion-book-container']}>
          <h2 className={styles['suggestion-heading']}>Top Results</h2>
          {resultsForIndex('titles')
            .hits?.slice(0, MAX_VISIBLE_RESULTS.titles)
            .map(({ document: suggestion }, index) => {
              return (
                <Animated key={suggestion.id} animation="fadeIn" duration={0.5}>
                  <div
                    className={classNames(styles['suggestion-book'], {
                      [styles['suggestion-book-active']]:
                        activeResult === suggestion.id,
                    })}
                  >
                    <Link
                      href={Links.title(suggestion).show}
                      passHref
                      shallow
                      onClick={() => {
                        trackProductClick(suggestion, 'titles', index);
                        analyticEvents?.generalTrack('Search Action', {
                          type: 'title-click',
                          source: 'search-suggestions',
                        });
                      }}
                      onMouseEnter={() => handleHoverResult(suggestion.id)}
                      onMouseLeave={() => handleHoverResult('')}
                      onFocus={() => {
                        handleHoverResult(suggestion.id);
                      }}
                      className={classNames(styles['suggestion-book-content'])}
                    >
                      <div className={styles['suggestion-book-image']}>
                        {Boolean(titlePhotoPath(suggestion)) && (
                          <LoadableImage
                            key={suggestion?.id}
                            width={150}
                            height={150}
                            src={images(titlePhotoPath(suggestion)).book.tiny}
                            alt={suggestion.title}
                          />
                        )}
                      </div>
                      <div className={styles['suggestion-book-info']}>
                        <h3>{suggestion.title}</h3>
                        {suggestion.authors?.length ? (
                          <h4>{suggestion.authors[0].name}</h4>
                        ) : null}
                        {renderTitlePrice(suggestion)}
                      </div>
                    </Link>
                    <Button
                      text="Shop all"
                      size="small"
                      style="clear"
                      onPress={() => {
                        analyticEvents?.generalTrack('Search Action', {
                          type: 'shop-all',
                          source: 'search-suggestions',
                        });
                      }}
                      link={{
                        href: Links.searchWithQueryAppRouter({
                          pathname,
                          searchParams,
                          path: '/search',
                          options: {
                            index: 'books',
                            params: {
                              q: '*',
                              sort_by: mapSortBy('books'),
                              query_by: mapQueryBy('books'),
                              filter_by: `title_id:${suggestion.id}`,
                            },
                            options: {},
                          },
                        }).url,
                      }}
                    />
                  </div>
                </Animated>
              );
            })}
        </div>
      );
    }
  }

  function renderAuthorSuggestions() {
    if (!HAS_AUTHOR_SUGGESTIONS || ON_SELLERS_SEARCH_PAGE) return null;

    return (
      <div className={styles['suggestion-user-container']}>
        <h2 className={styles['suggestion-heading']}>Authors</h2>
        {resultsForIndex('authors')
          .hits?.slice(0, MAX_VISIBLE_RESULTS.authors)
          .map(({ document: suggestion }) => {
            return (
              <Animated key={suggestion.id} animation="fadeIn" duration={0.5}>
                <Link
                  href={Links.author.slug({ slug: suggestion.id }).pathname}
                  passHref
                  onClick={() => {
                    analyticEvents?.generalTrack('Search Action', {
                      type: 'author-click',
                      source: 'search-suggestions',
                    });
                  }}
                  onMouseEnter={() => handleHoverResult(suggestion.id)}
                  onMouseLeave={() => handleHoverResult('')}
                  onFocus={() => {
                    handleHoverResult(suggestion.id);
                  }}
                  className={classNames(styles['suggestion-user'], {
                    [styles['suggestion-user-active']]:
                      activeResult === suggestion.id,
                  })}
                >
                  <div
                    className={classNames(
                      styles['suggestion-user-image'],
                      styles[`suggestion-user-image-round`]
                    )}
                  >
                    <div className={styles['author-img']}>
                      {suggestion.photo_url ? (
                        <Image
                          src={images(suggestion.photo_url).user.profileImage}
                          width={200}
                          height={200}
                          className={styles['author-img']}
                          unoptimized
                          style={{
                            maxWidth: '100%',
                            height: 'auto',
                          }}
                          alt="author profile image"
                        />
                      ) : (
                        <div className={styles['suggestion-author-SVG']}>
                          <ProfileSVG strokeColor={COLORS.primary} />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles['suggestion-user-info']}>
                    <div className={styles['suggestion-user-callout']}>
                      <h3>{suggestion?.fullname}</h3>
                    </div>
                  </div>
                </Link>
              </Animated>
            );
          })}
      </div>
    );
  }

  function renderUserSuggestions() {
    if (HAS_USER_SUGGESTIONS) {
      return (
        <div className={styles['suggestion-user-container']}>
          <h2 className={styles['suggestion-heading']}>People</h2>
          {resultsForIndex('users')
            .hits?.slice(
              0,
              ON_SELLERS_SEARCH_PAGE ? 15 : MAX_VISIBLE_RESULTS.user
            )
            .map(({ document: suggestion }) => {
              return (
                <Animated key={suggestion.id} animation="fadeIn" duration={0.5}>
                  <Link
                    href={Links.bookstore(suggestion).show}
                    passHref
                    shallow
                    onClick={() => {
                      analyticEvents?.generalTrack('Search Action', {
                        type: 'user-click',
                        source: 'search-suggestions',
                      });
                    }}
                    onMouseEnter={() => handleHoverResult(suggestion.id)}
                    onMouseLeave={() => handleHoverResult('')}
                    onFocus={() => {
                      handleHoverResult(suggestion.id);
                    }}
                    className={classNames(styles['suggestion-user'], {
                      [styles['suggestion-user-active']]:
                        activeResult === suggestion.id,
                    })}
                  >
                    <div
                      className={classNames(
                        styles['suggestion-user-image'],
                        styles['suggestion-user-image-round']
                      )}
                    >
                      <UserProfileImage user={suggestion} link={false} />
                    </div>
                    <div className={styles['suggestion-user-info']}>
                      <div className={styles['suggestion-user-callout']}>
                        <h3>{suggestion.name}</h3>
                        {isHighQualitySeller(suggestion) && <QualitySVG />}
                        {isFastShipper(suggestion) && <FastShipperSVG />}
                      </div>

                      <div className={styles['suggestion-user-reviews']}>
                        <UserReviewStars
                          user={suggestion}
                          withCountText
                          inline
                        />
                      </div>
                      <h4>@{suggestion.username.normalize('NFKD')}</h4>
                    </div>
                  </Link>
                </Animated>
              );
            })}
        </div>
      );
    }
  }

  function renderClearSearchInput() {
    if (searchQuery.length === 0) {
      //  return the search icon
      return (
        <div className={styles['search-icon']}>
          <SearchIcon strokeColor={COLORS.lightGrey} />
        </div>
      );
    }

    return (
      <span
        onClick={handleClearSearch}
        className={classNames(styles['input-clear'], {
          [styles['input-clear--active']]: showSuggestionsDropdown,
        })}
      >
        x
      </span>
    );
  }

  function renderShowAllLink() {
    if (ON_SEARCH_PAGE) return null;

    const SHOULD_SHOW_SHOW_ALL_LINK =
      resultsForIndex('books')?.found > MAX_VISIBLE_RESULTS.product &&
      HAS_SEARCH_QUERY &&
      HAS_SEARCH_PRODUCT_SUGGESTIONS;

    if (SHOULD_SHOW_SHOW_ALL_LINK) {
      return (
        <Animated animation="fadeIn" duration={0.5}>
          <div
            className={styles['show-more']}
            onMouseEnter={() => setActiveResult(null)}
          >
            <Link
              passHref
              href={SEARCH_LINK}
              onClick={() => {
                analyticEvents?.generalTrack('Search Action', {
                  type: 'see-all-books-click',
                  source: 'search-suggestions',
                });
              }}
            >
              See All Books Results ({resultsForIndex('books').found})
            </Link>
          </div>
        </Animated>
      );
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles['input-container']}>
        <form onSubmit={handleSearchInputSubmit}>
          <input
            ref={searchInputRef}
            onFocus={() => handleOnInputFocus()}
            onBlur={() => setSearchInputIsFocused(false)}
            onChange={(e) => handleInputChange(e.target.value)}
            onClick={() => handleOnInputFocus()}
            value={searchQuery}
            className={classNames(styles.input, {
              [styles['input-active']]: showSuggestionsDropdown,
            })}
            type="search"
            placeholder={ON_SELLERS_SEARCH_PAGE ? 'Search for shops' : 'Search'}
            id="search-input"
          />
        </form>
        {renderClearSearchInput()}
      </div>
      {showSuggestionsDropdown && (
        <Animated animation="fadeIn" duration={0.5}>
          <ul
            className={styles['suggestions-container']}
            ref={suggestionsContainerRef}
          >
            {renderTitleSuggestions()}
            {renderProductSuggestions()}
            {renderMultipleBookSearch()}
            {renderAuthorSuggestions()}
            {renderUserSuggestions()}
            {renderHashtagSuggestions()}
            {renderShowAllLink()}
          </ul>
        </Animated>
      )}
    </div>
  );
}
